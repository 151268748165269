<template>
  <div class="page-template">
    <div class="page-template__header">
      <back-button class="page-template__header__btn-back" />
      <span class="page-template__header__title">
        {{ title }}
      </span>
      <slot name="header"></slot>
    </div>
    <div class="page-template__content">
      <slot />
    </div>
  </div>
</template>

<script>
import BackButton from '@/elements/components/BackButton';

export default {
  name: 'PageTemplate',
  components: {
    BackButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass">
.page-template
  display: flex
  flex: 1
  flex-direction: column
  width: 100%
  height: 100%
  padding: base-unit(12)
  background-color: white
  overflow-y: auto
  +medium-devices
    padding: base-unit(30)

  &__header
    display: flex
    align-items: center
    position: relative

    &__btn-back
      display: flex
      +medium-devices
        margin-right: base-unit(8)
        position: relative

    &__title
      position: absolute
      left: 50%
      transform: translateX(-50%)
      font-size: base-unit(16)
      font-weight: 500
      color: grey-color('500')
      margin: 0 auto
      +medium-devices
        margin: 0

  &__content
    display: flex
    flex: 1
    margin-top: base-unit(20)
</style>
