export const buildRequestParams = (data) => {
  const mapPersonData = (personData) => ({
    date: personData.birthDate,
    time: personData.birthTime,
    place: personData.birthPlace,
    lat: personData.birthLat,
    long: personData.birthLong,
    mapType: personData.mapType,
    cosmogram: personData.cosmogram,
    gmtVal: personData.birthGMT,
    autoGmt: personData.autoGmt,
    autoHouse: personData.autoHouse,
    houseSystem: personData.houseSystem,
  });

  const result = {
    firstPersonData: mapPersonData(data.map1),
    chartType: data.chartType,
    mapName: data.mapName,
    noSave: true,
  };
  if (data.map2) {
    result.secondPersonData = mapPersonData(data.map2);
  }
  return result;
};

export const buildDataForInputForm = (personData) => {
  const result = mapPersonData(personData);
  console.log(personData);
  delete result.houseSystem;
  result.houseSystem = personData.houseSystem;
  result.birthPlaceType = personData.place ? 'name' : 'coords';
  result.autoHouse = personData.autoHouse;
  result.autoGmt = personData.autoGmt;

  return result;
};

export const mapPersonData = (personData, index = '') => {
  return {
    [`birthDate${index}`]: personData.date,
    [`birthTime${index}`]: personData.time,
    [`birthGMT${index}`]: personData.dateShift,
    [`birthPlace${index}`]: personData.place,
    [`birthLat${index}`]: personData.lat,
    [`birthLong${index}`]: personData.long,
    [`birthLatInDegrees${index}`]: personData.latitudeInDegrees,
    [`birthLongInDegrees${index}`]: personData.longitudeInDegrees,
    [`mapType${index}`]: personData.mapType,
    [`houseSystem${index}`]: personData.houseSystem,
    [`cosmogram${index}`]: personData.cosmogram,
  };
};

export const buildQueryParamsFromRequestParams = (data) => {
  return {
    chartId: data.chartId,
    chartType: data.chartType,
    ...mapPersonData(data.firstPersonData),
    ...(data.secondPersonData ? mapPersonData(data.secondPersonData, 2) : {}),
  };
};

export const chartQueryParamsList = [
  'chartId',
  'chartType',
  ...['birthDate', 'birthTime', 'birthPlace', 'birthLat', 'birthLong', 'mapType', 'houseSystem', 'cosmogram'].flatMap(
    (name) => [name, `${name}2`],
  ),
];
