<template>
  <label class="text-field-label">
    {{ label }}
    <sup v-if="required">*</sup>
    <template v-if="showPlaceInputs">
      <div v-if="!hidePlaceTypesButtons" class="birth_type-select">
        <v-radio-group
          v-if="!hidePlaceTypesButtons"
          v-model="form.birthPlaceType"
          :mandatory="false"
          class="birth_type-choice"
        >
          <div class="birth_type-item">
            <v-radio value="coords">
              <template v-slot:label>
                <v-select
                  class="coords_select"
                  :items="coords"
                  v-model="currentCoords"
                  value.sync="type"
                  item-value="type"
                  item-text="title"
                  label="Координаты десятичные"
                  solo
                  single-line
                >
                </v-select>
              </template>
            </v-radio>
          </div>
        </v-radio-group>
        <v-radio-group
          v-if="form.birthPlaceType && !hidePlaceTypesButtons"
          v-model="form.birthPlaceType"
          :mandatory="false"
          class="birth_type-choice"
        >
          <div class="birth_type-item">
            <v-radio value="name">
              <template v-slot:label> {{ $t('createMap.or') }} {{ $t('createMap.onName') }} </template>
            </v-radio>
          </div>
          <!--          <div class="birth_type-item" v-else-if="form.birthPlaceType !== 'map'">
                      <v-radio value="map">
                        <template v-slot:label>
                          или найти на карте
                        </template>
                      </v-radio>
          </div>-->
          <!--          <div class="birth_type-item" v-if="form.birthPlaceType !== 'coords'">
                      <v-radio label="указать координаты" value="coords"></v-radio>
                    </div>-->
        </v-radio-group>
      </div>
      <v-autocomplete
        v-if="form.birthPlaceType === 'name'"
        v-model="form.birthPlace"
        :items="cities"
        :loading="isLoading"
        :search-input.sync="search"
        :rules="requiredRules"
        hide-no-data
        hide-selected
        :placeholder="hidePlaceTypesButtons ? '' : $t('createMap.typeToSearch')"
        class="required autocomplete-search"
        @change="onBirthPlaceChanged"
      >
        <template #item="{ item, on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="v-autocomplete-cities-list-item">
            <v-list-item-title>{{ item }}</v-list-item-title>
            <img class="powered-by-google" :src="require('@/assets/logo/powered-by-google.png')" />
          </v-list-item>
        </template>
      </v-autocomplete>
      <!--      <div class="map" v-if="form.birthPlaceType === 'map'">
        <form-map @clicked="closeMap" @select-coords="setCoords" />
      </div>
      <div class="fade" v-if="form.birthPlaceType === 'map'"></div>-->

      <div v-if="form.birthPlaceType === 'coords'">
        <!--        <text-field
          @change="clearBirthPlace"
          @keyup="calcCoordsLat"
          :label="'Широта'"
          :rules="requiredRules"
          v-model="lat"
          v-mask="'##°##′##″'"
          placeholder="Широта"
          type="text"
          class="required"
        />-->
        <!--         <input-coords
            :label="$t('createMap.lat')"
            :value.sync="lat"
            :mask="maskLat"
            @calc-coords="calcCoordsLat"
            type="text"
            class="required"
          ></input-coords>
          <input-coords
            :label="$t('createMap.long')"
            :value.sync="long"
            :mask="maskLong"
            @calc-coords="calcCoordsLong"
            type="text"
            class="required"
          ></input-coords>-->
        <!--        <text-field
          @change="clearBirthPlace"
          @keyup="calcCoordsLat"
          :label="'Широта'"
          :rules="requiredRules"
          v-model="lat"
          v-mask="timeRangeMaskLat"
          placeholder="Широта"
          type="text"
          class="required"
        />
        <text-field
          @change="clearBirthPlace"
          @keyup="calcCoordsLong"
          :label="'Долгота'"
          :rules="requiredRules"
          v-model="long"
          v-mask="timeRangeMaskLong"
          placeholder="Долгота"
          type="text"
          class="required"
        />-->
        <div class="inputs-block__grid" v-if="currentCoords === 'coords-ten'">
          <div class="inputs-block-item">
            <text-field
              @change="clearBirthPlace;"
              @keyup="latBorder($event.target.value, 90)"
              :label="$t('createMap.lat')"
              :rules="requiredRules"
              v-model="lat"
              type="text"
              class="required"
              hint="Например, 54.9914"
              persistent-hint
            />
            <!--            <span>Например, 54.9914</span>-->
          </div>
          <div class="inputs-block-item">
            <text-field
              @change="clearBirthPlace"
              @keyup="longBorder($event.target.value, 180)"
              :label="$t('createMap.long')"
              :rules="requiredRules"
              v-model="long"
              type="text"
              class="required"
              hint="Например, 73.3645"
              persistent-hint
            />
            <!--            <span>Например, 73.3645</span>-->
          </div>
        </div>
        <CoordsDegrees v-if="currentCoords === 'coords-deg'" :lat.sync="lat" :long.sync="long" />
      </div>

      <!--      <v-radio-group
        v-if="form.birthPlaceType && !hidePlaceTypesButtons"
        v-model="form.birthPlaceType"
        :mandatory="false"
        class="birth_type-choice"
      >
        <div class="birth_type-item" v-if="form.birthPlaceType !== 'name'">
          <v-radio label="Ввести название" value="name"></v-radio>
          <span>или</span>
        </div>
        <div class="birth_type-item" v-if="form.birthPlaceType !== 'map'">
          <v-radio label="Найти на карте" value="map"></v-radio>
          <span>или</span>
        </div>
        <div class="birth_type-item" v-if="form.birthPlaceType !== 'coords'">
          <v-radio label="указать координаты" value="coords"></v-radio>
        </div>
      </v-radio-group>-->
    </template>
  </label>
  <!--        <v-radio-group
          v-if="form.birthPlaceType && !hidePlaceTypesButtons"
          v-model="form.birthPlaceType"
          :mandatory="false"
          class="birth_type-choice"
        >
          <div class="birth_type-item" v-if="form.birthPlaceType !== 'name'">
            <v-radio :label="$t('createMap.onName')" value="name"></v-radio>
            &lt;!&ndash;          <span>{{ $t('createMap.or') }}</span>&ndash;&gt;
          </div>
          &lt;!&ndash;        <div class="birth_type-item" v-if="form.birthPlaceType !== 'map'">
            <v-radio :label="$t('createMap.onMap')" value="map"></v-radio>
            <span>{{ $t('createMap.or') }}</span>
          </div>&ndash;&gt;
          <div class="birth_type-item" v-if="form.birthPlaceType !== 'coords'">
            <v-radio :label="$t('createMap.coords')" value="coords"></v-radio>
          </div>
        </v-radio-group>
      </template>
    </label>-->
</template>

<script>
/*import FormMap from './FormMap';*/
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import TextField from '@/elements/components/TextField';
import { TARIFF_PLAN } from '@/types';

const SEARCH_DELAY_MS = 500;
import { IMask } from 'vue-imask';
import CoordsContent from '@/elements/components/inputs/CoordsContent';
/*import InputCoords from '@/elements/components/inputs/InputCoords';*/
import InputCell from '@/elements/components/inputs/InputCell';
import CoordsDegrees from '@/elements/components/inputs/CoordsDegrees';
export default {
  name: 'SearchBirthPlace',
  // eslint-disable-next-line vue/no-unused-components
  components: { CoordsDegrees, TextField, CoordsContent, InputCell },
  props: {
    form: {
      type: Object,
      required: true,
    },
    showPlaceInputs: {
      type: Boolean,
      default: true,
    },
    hidePlaceTypesButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maskLat: {
        mask: 'ND°Z′Z″',
        lazy: false,
        placeholderChar: '#',
        blocks: {
          D: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 90,
            maxLength: 2,
            autofix: true,
          },
          N: {
            mask: '[#]',
            definitions: {
              // <any single char>: <same type as mask (RegExp, Function, etc.)>
              // defaults are '0', 'a', '*'
              '#': /[-]/,
            },
          },

          Z: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 99,
          },
        },
      },
      maskLong: {
        mask: 'ND\\°Z\\′Z″',
        lazy: false,
        placeholderChar: '#',
        blocks: {
          D: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 180,
            maxLength: 3,
            autofix: true,
          },
          N: {
            mask: '[#]',
            definitions: {
              // <any single char>: <same type as mask (RegExp, Function, etc.)>
              // defaults are '0', 'a', '*'
              '#': /[-]/,
            },
          },

          Z: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 99,
          },
        },
      },
      lat: '',
      long: '',
      title: '',
      cities: [],
      isLoading: false,
      search: null,
      /*requiredRules: [(v) => !!v || 'Поле должно быть заполнено'],*/
      TARIFF_PLAN,
      coords: [
        {
          type: 'coords-ten',
          title: 'Координаты десятичные',
        },
        {
          type: 'coords-deg',
          title: 'Координаты в градусах',
        },
      ],
      currentCoords: 'coords-ten',
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.form.birthPlaceType === 'name' && this.form.birthPlace) {
        this.cities.push(this.form.birthPlace);
        this.lat = this.form.birthLat;
        this.long = this.form.birthLong;
      } else if (this.form.birthPlaceType === 'coords' && this.form.birthLatInDegrees && this.form.birthLongInDegrees) {
        this.lat = this.form.birthLat;
        this.long = this.form.birthLong;
      }
    });
  },
  methods: {
    ...mapActions('ChartModule', ['getCities']),
    changeBirthPlaceType() {
      this.form.birthPlaceType = this.currentCoords;
    },
    setName(value) {
      this.title = value;
      this.$v.title.$touch();
    },
    clearBirthPlace() {
      this.form.birthPlace = '';
    },

    latBorder(event, border) {
      if (+event > border) {
        this.lat = border;
      } else if (+event < -border) {
        this.lat = -border;
      }

      this.form.birthLat = this.lat;
    },

    longBorder(event, border) {
      if (+event > border) {
        this.long = border;
      } else if (+event < -border) {
        this.long = -border;
      }

      this.form.birthLong = this.long;
    },
    calcCoordsLong() {
      let a;
      let b;
      let c;
      if (/-/g.test(this.long)) {
        a = this.long.replace(/[°′″]/gi, '').substring(0, 4);
        b = this.long.replace(/[°′″]/gi, '').substring(4, 6) * (1 / 60);
        c = this.long.replace(/[°′″]/gi, '').substring(6, 8) * (1 / 3600);
      } else {
        a = this.long.replace(/[°′″]/gi, '').substring(0, 3);
        b = this.long.replace(/[°′″]/gi, '').substring(3, 5) * (1 / 60);
        c = this.long.replace(/[°′″]/gi, '').substring(5, 7) * (1 / 3600);
      }
      let intB = (b + '').replace(/[.-0]/gi, '').substring(0, 3);
      let intC = (c + '').replace(/[.-0]/gi, '').substring(0, 3);
      this.form.birthLong = parseFloat(`${a}.${intB}${intC}`);
    },
    calcCoordsLat() {
      let a;
      let b;
      let c;
      if (/-/g.test(this.lat)) {
        if (/\s/g.test(this.lat)) {
          let trimmed = this.lat.replace(' ', '');
          console.log(trimmed);
          a = trimmed.replace(/[°′″]/gi, '').substring(0, 2);
          b = trimmed.replace(/[°′″]/gi, '').substring(3, 5) * (1 / 60);
          c = trimmed.replace(/[°′″]/gi, '').substring(5, 7) * (1 / 3600);
        } else {
          a = this.lat.replace(/[°′″]/gi, '').substring(0, 3);
          b = this.lat.replace(/[°′″]/gi, '').substring(3, 5) * (1 / 60);
          c = this.lat.replace(/[°′″]/gi, '').substring(5, 7) * (1 / 3600);
        }
      } else {
        if (/\s/g.test(this.lat)) {
          let trimmed = this.lat.replace(' ', '');
          a = trimmed.replace(/[°′″]/gi, '').substring(0, 1);
          b = trimmed.replace(/[°′″]/gi, '').substring(3, 5) * (1 / 60);
          c = trimmed.replace(/[°′″]/gi, '').substring(5, 7) * (1 / 3600);
        } else {
          a = this.lat.replace(/[°′″]/gi, '').substring(0, 2);
          b = this.lat.replace(/[°′″]/gi, '').substring(2, 4) * (1 / 60);
          c = this.lat.replace(/[°′″]/gi, '').substring(4, 6) * (1 / 3600);
        }
      }
      let intB = (b + '').replace(/[.-0]/gi, '').substring(0, 3);
      let intC = (c + '').replace(/[.-0]/gi, '').substring(0, 3);
      this.form.birthLat = parseFloat(`${a}.${intB}${intC}`);
    },
    closeMap() {
      this.form.birthPlaceType = 'coords';
    },
    setCoords(coords) {
      [this.lat, this.long] = coords;
      [this.form.birthLat, this.form.birthLong] = coords;
      this.clearBirthPlace();
    },
    processSearch: debounce(async function () {
      try {
        this.isLoading = true;
        const { predictions } = await this.getCities(this.search);
        this.cities = predictions;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }, SEARCH_DELAY_MS),
    onBirthPlaceChanged(value) {
      this.$emit('change', value);
      this.form.birthLong = '';
      this.form.birthLat = '';
    },
  },

  computed: {
    attrs() {
      // eslint-disable-next-line no-unused-vars
      const { label, ...attrs } = this.$attrs;

      return attrs;
    },
    label() {
      return this.$attrs.label;
    },
    required() {
      return this.$attrs.required;
    },
    requiredRules() {
      return [
        (v) =>
          !!v || (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
      ];
    },
  },

  watch: {
    search(query) {
      if (query && (!this.form.birthPlace || this.form.birthPlace !== query)) {
        this.processSearch();
      }
    },
    'form.cosmogram'(value) {
      if (!value) {
        return;
      }
      const now = new Date();
      this.form.birthTime = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    },
    lat() {
      this.form.birthLat = this.lat;
    },
    long() {
      this.form.birthLong = this.long;
    },
    'form.birthPlace'() {
      this.cities.push(this.form.birthPlace);
    },
  },
};
</script>

<style lang="sass">
.map
  position: absolute
  top: 10%
  left: 50%
  max-width: base-unit(1000)
  height: 100% !important
  max-height: base-unit(700)
  transform: translateX(-50%)
  z-index: 101
.fade
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  background: black-color('secondary')
  opacity: 0.7
  z-index: 100
.birth_type-choice
  //margin-left: auto
  align-items: flex-end
  .birth_type-item
    display: flex
    align-items: center
    white-space: nowrap
    .v-label
      color: blue-color('primary')
    span
      margin: 0 3px
      line-height: 20px
      display: inline-block
    //&:last-child
      span
        display: none
  .v-input--radio-group__input
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end
  .v-input--selection-controls__input
    display: none
  .v-radio
    &:not(:last-child)
      margin-bottom: 0 !important
.text-field-label
  color: grey-color('500')
  sup
    font-size: base-unit(12)
    color: blue-color('primary')
.autocomplete-search
  padding-top: 0
  margin-top: 0
  .v-input__slot
    border: base-unit(1) solid grey-color('75')
    border-radius: base-unit(4)
    padding: base-unit(3) base-unit(16)
    &:before
      border-color: grey-color('75')
  .v-input__append-inner
    display: none
.v-autocomplete-cities-list-item
  .powered-by-google
    display: none
  &:last-child
    position: relative
    .powered-by-google
      display: block
      position: absolute
      right: base-unit(5)
      bottom: base-unit(-5)

.birth_type-select
  display: flex
  align-items: center
  flex-wrap: wrap
  margin-bottom: base-unit(10)
  .v-input__slot
    margin-bottom: 0
  .v-messages
    display: none
</style>
