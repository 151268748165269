<template>
  <div>
    <div class="input_cell-wrapper">
      <input-cell
        @check-val="checkVal"
        :error.sync="error"
        :lat.sync="latDeg"
        :main="true"
        symbol="°"
        type="text"
        class="required"
      />
      <input-cell :error.sync="error" :lat.sync="latMin" symbol="’" type="text" class="required" />
      <input-cell :error.sync="error" :lat.sync="latSec" symbol="”" type="text" class="required" />
      <v-select
        class="coords_input-select"
        :items="lats"
        v-model="latDirection"
        value.sync="value"
        item-value="value"
        item-text="label"
        label="N"
        solo
        outlined
        hide-details="auto"
      ></v-select>
      <input-cell
        @check-val="checkVal"
        :main="true"
        :error.sync="error"
        :long.sync="longDeg"
        symbol="°"
        type="text"
        class="required"
      />
      <input-cell :error.sync="error" :long.sync="longMin" symbol="’" type="text" class="required" />
      <input-cell :error.sync="error" :long.sync="longSec" symbol="”" type="text" class="required" />
      <v-select
        class="coords_input-select"
        :items="longs"
        v-model="longDirection"
        value.sync="value"
        item-value="value"
        item-text="label"
        label="W"
        solo
        outlined
        hide-details="auto"
      ></v-select>
    </div>
    <transition name="slide-fade">
      <span class="error--text error--field" v-if="error">Поле должно быть заполнено</span>
    </transition>
  </div>
</template>

<script>
import InputCell from '@/elements/components/inputs/InputCell';

export default {
  name: 'CoordsDegrees',
  props: {
    lat: [Number, String],
    long: [Number, String],
  },
  components: { InputCell },
  data() {
    return {
      error: false,
      latDirection: 'positive',
      longDirection: 'positive',
      lats: [
        {
          label: 'N',
          value: 'positive',
        },
        {
          label: 'S',
          value: 'negative',
        },
      ],
      longs: [
        {
          label: 'W',
          value: 'positive',
        },
        {
          label: 'E',
          value: 'negative',
        },
      ],
      latDeg: '',
      latMin: '',
      latSec: '',
      longDeg: '',
      longMin: '',
      longSec: '',
    };
  },
  methods: {
    checkVal(event) {
      console.log(event);
    },
  },
  computed: {
    fullLatDegs() {
      let convertedMins = (+this.latMin / 60).toFixed(5);
      let convertedSecs = (+this.latSec / 3600).toFixed(5);
      if (this.latDirection === 'negative') {
        return -(+this.latDeg + +convertedMins + +convertedSecs);
      } else {
        return +this.latDeg + +convertedMins + +convertedSecs;
      }
    },
    fullLongDegs() {
      let convertedMins = (+this.longMin / 60).toFixed(5);
      let convertedSecs = (+this.longSec / 3600).toFixed(5);
      if (this.longDirection === 'negative') {
        return -(+this.longDeg + +convertedMins + +convertedSecs);
      } else {
        return +this.longDeg + +convertedMins + +convertedSecs;
      }
    },
  },
  watch: {
    latDeg(value) {
      if (value == 90) {
        this.latMin = '00';
        this.latSec = '00';
      } else {
        /*this.latMin = '';
        this.latSec = '';*/
      }
      //this.lat = this.fullLatDegs;
    },
    longDeg(value) {
      if (value == 180) {
        this.longMin = '00';
        this.longSec = '00';
      } else {
        /*this.longMin = '';
        this.longSec = '';*/
      }
      //this.long = this.fullLongDegs;
    },
  },
  mounted() {
    if (this.lat !== '') {
      if (/-/g.test(this.lat.toString())) {
        this.latDirection = 'negative';
      }
      this.latDeg = Math.abs(Math.trunc(this.lat));
      let unCutedMins = (+Math.abs(this.lat) - Math.abs(this.latDeg)).toFixed(5) * 60;
      this.latMin = Math.abs(parseInt(unCutedMins));
      this.latSec = Math.abs(parseInt((unCutedMins - this.latMin).toFixed(5) * 60));
    }
    if (this.long !== '') {
      if (/-/g.test(this.long.toString())) {
        this.longDirection = 'negative';
      }
      this.longDeg = Math.abs(Math.trunc(this.long));
      let unCutedMins = (+Math.abs(this.long) - Math.abs(this.longDeg)).toFixed(5) * 60;
      this.longMin = Math.abs(parseInt(unCutedMins));
      this.longSec = Math.abs(parseInt((unCutedMins - this.longMin).toFixed(5) * 60));
    }
  },
  updated: function () {
    this.$nextTick(function () {
      this.$emit('update:lat', this.fullLatDegs);
      this.$emit('update:long', this.fullLongDegs);
    });
  },
};
</script>
<style lang="sass" scoped>
.error--field
  font-size: 12px
  line-height: 14px
  color: #EB5757 !important
</style>
<style lang="sass">
.input_cell-wrapper
  display: grid
  column-gap: base-unit(6)
  row-gap: base-unit(13)
  grid-template-columns: repeat(4, 65px)
  grid-template-rows: base-unit(40)
  max-width: base-unit(260)
  width: 100%
.coords_input-select.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control
  min-height: 100%
  //.v-text-field.v-text-field--enclosed .v-text-field__details
    display: none
.coords_input-select.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot
  box-shadow: none
  min-height: 100%
  margin-bottom: 0
  height: base-unit(39)
  padding-left: base-unit(16)
  .v-select__selection--comma
    overflow: visible
</style>
