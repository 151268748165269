<template>
  <action-view @click="toMainPage" class="back-btn">
    <div>
      <img :src="require('@/assets/icons/arrow-back.svg')" :alt="$t('back')" />
    </div>
    <span>{{ $t('back') }}</span>
  </action-view>
</template>
<script>
import ActionView from '@/elements/atoms/ActionView';

export default {
  name: 'BackButton',
  components: {
    ActionView,
  },
  computed: {
    to() {
      const { to } = this.$route.params;
      return to ? to : { name: 'natal-chart' };
    },
  },
  methods: {
    toMainPage() {
      this.$router.push(this.to);
    },
  },
};
</script>
<style lang="sass" scoped>
.back-btn
  span
    font-size: base-unit(16)
    font-weight: 500
    color: blue-color('primary')
    margin-left: base-unit(6)
    display: none
    +medium-devices
      display: block
  div
    width: base-unit(24)
    height: base-unit(24)
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    background: #EDF4F7
    box-shadow: 0px 2px 4px rgba(22, 126, 162, 0.075)
    img
      width: base-unit(16)
      height: base-unit(16)
</style>
