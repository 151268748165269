<template>
  <div>
    <v-select
      class="coords_select"
      :items="coords"
      v-model="currentCoords"
      value.sync="type"
      item-value="type"
      item-text="title"
      label="Координаты десятичные"
      solo
      single-line
    >
    </v-select>
    <div v-if="currentCoords === '1'">1</div>
    <div v-if="currentCoords === '2'">2</div>
  </div>
</template>

<script>
export default {
  name: 'CoordsContent',
  data() {
    return {
      coords: [
        {
          type: '1',
          title: 'Координаты десятичные',
        },
        {
          type: '2',
          title: 'Координаты в градусах',
        },
      ],
      currentCoords: '',
    };
  },
};
</script>

<style lang="sass">
.coords_select.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot
  box-shadow: none
  padding: 0
  .v-select__selection--comma
    overflow: visible
    text-overflow: unset

.coords_select
  .v-input__control
    height: 50px
    width: 217px
  .v-label
    overflow: visible
    text-overflow: unset
</style>
