<template>
  <div class="input_cell">
    <v-text-field
      :class="{ 'error--text': valError }"
      hide-details="auto"
      dense
      outlined
      v-bind="attrs"
      v-on="$listeners"
      v-model="text"
      @keyup="checkSomeValue($event.target.value)"
    />
    <span :class="{ 'error--text': valError }">{{ symbol }}</span>
  </div>
</template>

<script>
export default {
  name: 'InputCell',
  props: {
    symbol: String,
    error: Boolean,
    lat: [String, Number],
    long: [String, Number],
    main: Boolean,
  },
  data() {
    return {
      valError: false,
      text: '',
    };
  },
  computed: {
    attrs() {
      // eslint-disable-next-line no-unused-vars
      const { label, ...attrs } = this.$attrs;

      return attrs;
    },
    required() {
      return this.$attrs.required;
    },
  },

  methods: {
    checkSomeValue(event) {
      if (typeof this.lat !== 'undefined' && this.main) {
        console.log('lat');
        if (+event > 90) {
          this.text = 90;
        } else if (+event < -90) {
          this.text = -90;
        }
      } else if (typeof this.long !== 'undefined' && this.main) {
        console.log('long');
        if (+event > 180) {
          this.text = 180;
        } else if (+event < -180) {
          this.text = -180;
        }
      }
    },
  },

  watch: {
    text(value) {
      this.$emit('update:lat', this.text);
      this.$emit('update:long', this.text);
      if (value === '') {
        this.$emit('update:error', true);
        this.valError = true;
      } else {
        this.$emit('update:error', false);
        this.valError = false;
      }
    },
    lat(value) {
      this.text = value;
    },
    long(value) {
      this.text = value;
    },
  },
};
</script>

<style lang="sass">
.input_cell
  display: flex
  column-gap: base-unit(6)
.error--text
  color: red
.v-text-field.error--text
  fieldset
    border-color: red
</style>
