<template>
  <div class="page-subtitle">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageSubtitle',
};
</script>

<style scoped lang="sass">
.page-subtitle
  font-weight: 400
  color: grey-color('500')
  margin-top: 0
  margin-bottom: base-unit(10)
  +base-font-size
</style>
